import types from './mutationTypes';
import { isEqual, upperFirst, capitalize, assign, range } from 'lodash';
import gamesStatic from '@nsftx/games-sdk-js/src/api/gamesStatic';
import { ConfigurationService } from '@nsftx/games-config';
import i18n from '../i18n';
import { ticketAPI, historyAPI, upcomingEventsAPI, resultsAPI } from '@/api';
import { betslipUtility, configUtility, ticketCheck, eventBus, busService, errorTicketHandler } from '../utility';
import login from '../api/login';
import { gtag } from '@nsftx/games-sdk-js/src/utility';

const qp = new URLSearchParams(window.location.search);
const deliveryPlatform = qp.get('deliveryPlatform') || qp.get('application');
const productId = qp.get('productId');
const isWebTerminal = qp.get('isWebTerminal');
const deviceId = qp.get('deviceId');
const token = qp.get('token');
const tenantId = qp.get('tenantId');

export default {
  async initApp({ dispatch, state }) {
    // INTEGTATION LOGIN
    if (isWebTerminal) {
      await login.sevenAuth(productId, token, tenantId).catch(() => {
        dispatch('handleSubscriptionNotActive', 'login_failed');
      });
      if (!state.appShouldCountinueInit) return;
    }
    // CONFIG SERVICE
    await dispatch('initConfig').catch(async () => {
      await dispatch('handleSubscriptionNotActive', 'config_error');
    });

    if (!state.appShouldCountinueInit) return;
    // https://app.clickup.com/t/86930fk2n
    const isSubscriptionActive = state.config.rawData?.isActive;
    if (!isSubscriptionActive) await dispatch('handleSubscriptionNotActive', 'subscription_not_active');
    if (!state.appShouldCountinueInit) return;

    await dispatch('getTranslations');
    await dispatch('setBetslipConfig');
    await dispatch('setDeviceLayout');
    await dispatch('getUpcomingEvents');

    upcomingEventsAPI
      .getUpcomingEvents()
      .then(async (upcomingEvents) => {
        await dispatch('setUpcomingEvents', upcomingEvents);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting upcoming events', error);
      });

    resultsAPI
      .getPreviousResults()
      .then((response) => {
        dispatch('setResults', response);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    busService.init();
  },
  getUpcomingEvents({ dispatch }) {
    upcomingEventsAPI
      .getUpcomingEvents()
      .then(async (upcomingEvents) => {
        await dispatch('setUpcomingEvents', upcomingEvents);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting upcoming events', error);
      });
  },
  async reInitBusService({ getters, dispatch }) {
    if (!getters.socketReInitInProgress) {
      busService.disconnectAdapters('Connection closed due to reconnection attempt');
      busService.init().then(async () => {
        await dispatch('getUpcomingEvents');
      });
    } else {
      setTimeout(() => {
        dispatch('reInitBusService');
      }, 100);
    }
  },
  async initConfig({ dispatch, commit }) {
    if (isWebTerminal) {
      dispatch('setUserBalance', 1000); // TODO FOR TESTING ONLY
      commit(types.SET_IS_WEB_TERMINAL, isWebTerminal);
      commit(types.SET_DEVICE_ID, deviceId);
      commit(types.SET_TOKEN, token);
    }
    const slaveId = configUtility.getSlaveId(window.location.pathname);
    const messageProtocol = qp.get('messageProtocol') || qp.get('integrationType') || 'postMessage';
    const env = qp.get('env') || qp.get('environment');

    const configService = new ConfigurationService({
      environment: env ? env : process.env.NODE_ENV,
      requiredPaths: [],
      applicationName: deliveryPlatform === 'web' ? capitalize(deliveryPlatform) : deliveryPlatform,
      translationDomains: [], // Keep this at minimum
      messageProtocol,
      clientAdapters: [
        {
          name: `${upperFirst(messageProtocol)}Adapter`,
          options: {
            slaveId,
            eventPropagation: {
              click: true,
              keydown: '*',
            },
          },
        },
      ],
      ui: tenantId,
      channel: deliveryPlatform.toUpperCase(),
    });
    const config = await configService.getConfiguration();
    // should update betslip to work with new rules and config from backend
    const paymentStrategy = {
      terminal: {
        value: 'perBet',
      },
    };
    config.rules.paymentStrategy = paymentStrategy;
    await dispatch('setConfig', config);
    // INIT GOOGLE TAG MANAGER
    await dispatch('setupGtag');
  },
  async getTranslations({ dispatch, state }) {
    // TEMP solution for sr language
    if (state.config.locale === 'sr') state.config.locale = 'sr-Latn';
    const translations = await gamesStatic.getTranslations(state.config.locale);
    const localTranslations = (await i18n.common(state.config.locale)).default;
    await dispatch('setTranslations', assign(translations, localTranslations)); // sdk action
  },
  async setBetslipConfig({ dispatch, state, getters }) {
    const { rules, taxes, ui } = state.config;
    const betslipStaticConfig = ui.config?.betslip;

    // If taxes are turned off hide them
    if (!taxes.payin.policy) taxes.payin.hideTax = true;
    taxes.payout.hideTax = true;
    const betslipConfig = {
      betLayout: 'A',
      channel: deliveryPlatform,
      ticketTypes: ['single'],
      rules: rules,
      taxes: taxes,
      isPossibleWinActive: true,
      currency: 'BAM',
      isPaymentBetAllowed: betslipStaticConfig?.isPaymentBetAllowed ?? true,
      isPaymentPerBetAllowed: betslipStaticConfig?.isPaymentPerBetAllowed ?? true,
      isFuturePerBetAllowed: false,
      isFuturePerTicketAllowed: betslipStaticConfig?.isFuturePerTicketAllowed ?? false,
      isTicketCancelAllowed: false,
      isTicketRebetAllowed: true,
      minIncrement: 0.01,
      futureRounds: range(1, 11),
    };
    dispatch('setTaxes', state.config.taxes);
    dispatch('gamesBetslip/setConfig', betslipConfig);
    dispatch('gamesBetslip/setActiveTicketType', getters['gamesBetslip/activeTicketTypes'][0]);
  },
  async handleTerminalMessages({ dispatch }, event) {
    const { data } = event.data;
    dispatch('user/setTerminalUser', true);
    const { action } = event.data;
    switch (action) {
      case 'Slave.Load': {
        const { device, betshop, company, account, user, terminal, settings, scrollButtons } = data;
        dispatch('setDeviceData', device);
        dispatch('setBetshopData', betshop);
        dispatch('setCompanyData', company);
        dispatch('setAccountData', account);
        dispatch('setArrowsVisibility', scrollButtons);
        dispatch('setUserBalance', terminal.balance);
        dispatch('setTerminalUserData', user);
        dispatch('setTerminalSetingsData', settings);
        break;
      }
      case 'Tickets.Checked': {
        dispatch('clearTicketCheckData');
        const ticketId = data?.ticket?.id;
        const ticketCheckTicket = await ticketAPI.checkTicketBarcode(ticketId).catch((error) => {
          errorTicketHandler(error);
        });
        if (!ticketCheckTicket) return;
        // add ticket pin if it exists
        ticketCheckTicket.productTicket.ticketPin = data?.ticket.ticketPin ? data?.ticket.ticketPin : null;
        dispatch('setTicketCheckData', ticketCheckTicket);
        setTimeout(() => {
          dispatch('setActiveComponent', 'TicketCheck');
        }, 200);
        break;
      }
      case 'Tickets.FetchHistory': {
        await dispatch('setTicketHistoryFetchInProgress', true);
        await dispatch('setTicketHistoryFetchData', data);
        const ticketHistory = await historyAPI.getTicketHistory();
        await dispatch('setTicketHistoryFetchInProgress', false);
        dispatch('setTicketHistory', ticketHistory.data);
        break;
      }
      case 'UI.Show': {
        dispatch('setActiveComponent', data.name[0]);
        break;
      }
      case 'Slave.Snooze': {
        dispatch('setActiveComponent', null);
        break;
      }
      case 'Betslip.Blocked':
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: data.blockers,
          type: 'add',
        });
        break;
      case 'Betslip.Unblocked':
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: data.blockers,
          type: 'remove',
        });
        break;
      case 'User.BalanceChanged':
        dispatch('setUserBalance', data.balance);
        break;
      case 'User.AuthorizationChanged':
        dispatch('setTerminalUserData', data);
        break;
      // FOR INTEGRATION
      case 'Game.StateChanged': {
        dispatch('handleIntegrationGameState', data);
        break;
      }
    }
    // TODO add support for betslip blokers
    // TODO cover case when something is wrong (eg: block betting ...)
    // if ((has(event.data.data, 'code') && event.data.data.code === 'error') || ((has(event.data.data, 'code')) && event.data.data.code === 'UNKNOWN')) {
    //   dispatch('betslip/lockBetslip', false);
    //   dispatch('setBetslipLockMessage', '');
    //   dispatch('setPayinProgress', false);
    //   dispatch('disable_betting', false);
    //   dispatch('betslip/disablePayinButton', false);
    //   dispatch('betslip/setTicketPayinLoader', false);
    // }
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      // eslint-disable-next-line no-console
      console.log('SSS Terminal: socket message:', event.data);
    }
  },
  async handleBusMessages({ state, dispatch, commit, getters }, payload) {
    const { eventName } = payload;
    switch (eventName) {
      // WebSocket game events
      case 'upcomingEvents':
        dispatch('setUpcomingEvents', payload.data);
        break;
      case 'SetIdle':
        // Handle countdown
        dispatch('handleSetIdle', payload);
        break;
      case 'StartEvent':
        dispatch('handleEvents', payload);
        dispatch('removeFirstEvent');
        break;
      case 'SetStep':
        dispatch('handleEvents', payload);
        break;
      case 'FinishEvent':
        if (getters.ticketCheckData) {
          try {
            const response = await ticketAPI.checkTicketBarcode(getters.ticketCheckData.id);
            dispatch('setTicketCheckData', response);
          } catch (error) {
            console.error(error);
          }
        }
        dispatch('handleEvents', payload);
        break;
      case 'TicketUpdate': {
        ticketCheck.stopTicketChecker(payload.data.ticket);
        if (!payload.data.error) {
          const { data } = payload;
          // TODO: Refactor for serbian taxId which will be on ticket level
          if (data.ticket.bets[0].additionalDetails?.taxId) await betslipUtility.mapBetGuids(data);

          // Send message over GGateway event 'Tickets.Update' to update state of ticket on platform
          const ticketData = {
            action: state.ticketActionMap[data.action] || data.action, // available values: Add, Cancel, Payout
            ticket: betslipUtility.prepareSevenTicketData(data.ticket),
          };
          eventBus.$emit('ticketUpdate', ticketData);
          // ANONYMOUS USER => PRINT ONLY ON TICKET PAYIN
          if (getters.terminalUser.userType === 'OPERATOR' || getters.terminalUser.type === 'OPERATOR') {
            eventBus.$emit('printTemplate', ticketData);
          } else if (state.actionsThatHavePrintOnlyOnTBO.indexOf(data.action) === -1) {
            eventBus.$emit('printTemplate', ticketData);
          }
          // ON PAYOUT AND CANCEL REFRESH TICKET CHECK DATA BUT ONLY ON ANONYMUS MODE
          // IN TBO SEVEN APP MAKES THE REQUEST
          if (
            getters.terminalUser.userType !== 'OPERATOR' &&
            (payload.data.action === 'Payout' || payload.data.action === 'Cancel')
          ) {
            dispatch('setTicketCheckData', payload.data.ticket);
          }
        }
        break;
      }
      case 'StopGame': {
        dispatch('handleSubscriptionNotActive', 'subscription_deactivated');
        break;
      }
      case 'ConnectionReconnect':
        dispatch('setReconnectState', true);
        eventBus.$emit('reInitSocketConnection');
        break;
      case 'ConnectionLost':
        dispatch('setReconnectState', false);
        break;
      case 'ConnectionSuccess':
        dispatch('setReconnectState', false);
        commit(types.RESET_RECONNECT_ATEMPTS_COUNTER);
        dispatch('setSocketReInitInProgress', false);
        break;
      case 'SetResults': {
        dispatch('setResults', payload.data.slice(0, 10));
        break;
      }
      default: {
        // console.log('unknown event name:', eventName);
      }
    }
  },
  setSocketReInitInProgress({ commit }, value) {
    commit(types.SET_SOCKET_REINIT_IN_PROGRESS, value);
  },
  setUpcomingEvents({ commit }, data) {
    commit(types.SET_UPCOMING_EVENTS, data);
  },
  removeFirstEvent({ commit, getters }) {
    commit(types.SET_UPCOMING_EVENTS, getters.upcomingEvents.slice(1));
  },
  async ticketRebet({ dispatch, getters }, payload) {
    const bets = [];
    let total = 0;
    payload.forEach((bet) => {
      const data = {
        betType: 1,
        outcomeId: +bet?.selections?.[0]?.outcomeId,
        odds: +bet?.selections?.[0]?.outcomeId,
        payment: +bet?.payment.real.value,
        stake: +bet?.payment.real.value,
        tax: 0,
        roundNumber: +bet?.selections?.[0]?.eventDisplayId,
        round: +bet?.selections?.[0]?.eventDisplayId,
        market: getters.getTranslation('crash_cash_market'),
        outcome: `x${(+bet?.selections?.[0]?.outcomeId).toFixed(2)}`,
        hideOdds: true,
      };
      total += data.payment;
      bets.push(data);
    });

    await dispatch('gamesBetslip/rebet', { bets, payin: total });
    const eventId = getters.eventInProgress ? getters.roundId + 1 : getters.roundId;
    dispatch('updateBetEvent', eventId);
  },
  setReconnectState({ commit }, value) {
    commit(types.SET_RECONNECT_STATE, value);
    if (value) commit(types.INCREASE_RECONNECT_ATEMPTS);
  },
  setActiveComponent({ state, commit }, component) {
    if (state.activeComponents.includes(component)) {
      commit(types.SET_ACTIVE_COMPONENT, component);
    } else {
      commit(types.SET_ACTIVE_COMPONENT, null);
    }
  },
  setTicketHistoryFetchData({ commit }, payload) {
    commit(types.SET_TICKET_HISTORY_FETCH_DATA, payload);
  },
  setTicketHistory({ commit }, payload) {
    commit(types.SET_TICKET_HISTORY, payload);
  },
  setTicketCheckData({ commit }, ticket) {
    commit(types.SET_TICKET_CHECK_DATA, ticket);
  },
  handleSetIdle({ state, commit, dispatch }, payload) {
    const { time, displayEventId, isBettingDisabled } = payload.data;
    // convert a time in milliseconds to seconds
    let eventTime = time >= 1000 ? parseInt(time / 1000, 10) : time;
    commit(types.SET_EVENT_TIME, --eventTime);
    commit(types.SET_EVENT_ID, displayEventId);
    if (!isEqual(displayEventId, state.eventId)) {
      dispatch('updateBetEvent', +displayEventId);
    }
    commit(types.SET_EVENT_NAME, payload.event);
    dispatch('setBettingDisabled', isBettingDisabled);
  },
  handleEvents({ commit, dispatch }, payload) {
    const { displayEventId } = payload.data;
    commit(types.SET_EVENT_ID, displayEventId);
    commit(types.SET_EVENT_NAME, payload.event);
    dispatch('updateBetEvent', parseInt(+displayEventId + 1));
    dispatch('setBettingDisabled', false);
  },
  updateBetEvent({ getters, dispatch }, payload) {
    const ticket = getters['gamesBetslip/ticket'];
    ticket.forEach((bet) => {
      dispatch('gamesBetslip/setRoundNumber', {
        betId: bet.id,
        roundNumber: payload,
      });
    });
  },
  // eslint-disable-next-line no-unused-vars
  sendGGMessage({ commit }, payload) {
    const action = 'Dialog.Show';
    const data = {
      action: '7S:Dialog.Show',
      message: payload.message,
      type: payload.type || 'warning',
      delay: payload.delay || 3000,
    };
    busService.sendMessage(action, data);
  },
  // eslint-disable-next-line no-unused-vars
  closeGGMessage({ commit }) {
    const action = 'Dialog.Show';
    busService.sendMessage(action, {});
  },
  setTerminalSetingsData({ commit }, settings) {
    commit(types.SET_TERMINAL_SETTINGS_DATA, settings);
  },
  setDeviceData({ commit }, device) {
    commit(types.SET_DEVICE, device);
  },
  setBetshopData({ commit }, betshop) {
    commit(types.SET_BETSHOP, betshop);
  },
  setCompanyData({ commit }, company) {
    commit(types.SET_COMPANY, company);
  },
  setAccountData({ commit }, account) {
    commit(types.SET_ACCOUNT, account);
  },
  setArrowsVisibility({ commit }, scrollButtons) {
    commit(types.SET_ARROWS_VISIBILITY, scrollButtons.value);
  },
  setUserBalance({ dispatch }, payload) {
    dispatch('user/setUserBalance', payload, { root: true });
  },
  setTerminalUserData({ commit }, payload) {
    commit(types.SET_TERMINAL_USER_DATA, payload);
  },
  setConfig({ commit, dispatch }, config) {
    dispatch('setStakeAndPick', config.ui.config);
    commit(types.SET_CONFIG, config);
  },
  setBettingDisabled({ commit }, isBettingDisabled) {
    commit(types.SET_BETTING_DISABLED, isBettingDisabled);
  },
  setStakeAndPick({ commit }, config) {
    const { crashCashPickValues, crashCashStakeValues } = config;
    if (crashCashPickValues) commit(types.SET_PICK_VALUES, crashCashPickValues);
    if (crashCashStakeValues) commit(types.SET_STAKE_VALUES, crashCashStakeValues);
  },
  setTaxes({ commit }, taxes) {
    commit(types.SET_TAXES, taxes);
  },
  clearSelection({ commit, getters }) {
    commit(types.CLEAR_SELECTION, getters.minBetAmount);
    commit(types.SET_OUTCOME_SELECTED, false);
    eventBus.$emit('clearInputSelection');
  },
  clearStake({ commit, getters }) {
    commit(types.CLEAR_STAKE, getters.minBetAmount);
    eventBus.$emit('clearInputStake');
  },
  setStake({ commit }, stake) {
    commit(types.SET_STAKE, stake);
  },
  // for now only one market for CC
  setMarket({ commit }, market) {
    commit(types.SET_MARKET, market);
  },
  setOutcome({ commit }, outcome) {
    commit(types.SET_OUTCOME, outcome.toFixed(2));
  },
  clearTicketCheckData({ commit }) {
    commit(types.CLEAR_TIKCET_CHECK_DATA);
  },
  setInputValue({ commit, state }, { value, id }) {
    if (id === 2) {
      commit(types.SET_STAKE_VALUE, value);
      return;
    }
    const pickValue = Number(value) ? Number(value) : Number(value.slice(1));
    const formatedPickValue = `x${pickValue.toFixed(2)}`;
    if (pickValue >= state.minOutcome) {
      commit(types.SET_PICK_VALUE, formatedPickValue);
    } else {
      commit(types.SET_PICK_VALUE, `x${state.minOutcome}`);
    }
  },
  setTicketAction({ commit }, payload) {
    commit(types.SET_TICKET_ACTION, payload);
  },
  setResults({ commit }, results) {
    commit(types.SET_RESULTS, results);
  },
  handleSubscriptionNotActive({ commit }, reason) {
    const reasons = {
      subscription_not_active: 'Subscription not active',
      subscription_deactivated: 'Subscription deactivated',
      login_failed: 'Login failed',
      config_error: 'Config error',
    };
    commit(types.SET_GAME_NOT_ACTIVE, reasons[reason]);
    commit(types.SET_APP_SHOULD_CONTINUE_INIT, false);
  },
  handleIntegrationGameState({ dispatch }, payload) {
    dispatch('setUserBalance', payload.balance);
    dispatch('setIsPayinButtonDisabled', !payload.payinAllowed);
  },
  setupGtag({ getters }) {
    const { code, auth, preview } = getters.googleTagConfig;
    gtag.setup(code, auth, preview);
  },
  setTicketHistoryFetchInProgress({ commit }, payload) {
    commit(types.SET_HISTORY_FETCH_IN_PROGRESS, payload);
  },
};
